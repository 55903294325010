
export default [
    {
        path: '/tds',
        component: "__base",
        otherdata: { color: "ccompliance" },
        children: [
            {
                // module: "TDS",
                path: "project",
                component: "ARList.vue",
                meta: { title: "AR Analysis List" },
            },
            {
                // module: "TDS",
                path: "project/view/:id",
                component: "ARView.vue",
                meta: { title: "AR Analysis Details" },
            },
            {
                // module: "TDS",
                path: "*",
                component: "",
            },
        ],
    },
]

